<template>
    <v-row no-gutters>
        <v-col cols="12">
            <AccountBar :organizationId="$route.params.organizationId" :account="account" class="mb-6" v-if="account"></AccountBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link>
                    <template v-if="organization">
                    &gt; <router-link :to="{ name: 'organization-dashboard', params: { realm: this.$route.params.organizationId } }">{{ organization.name }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card class="pa-0" v-if="Array.isArray(userList)">
                        <v-app-bar color="green darken-2" dark flat dense>
                            <v-app-bar-title>Users ({{ userList.length }})</v-app-bar-title>
                            <v-spacer/>
                            <!-- TODO: enable invite new users only for organizations with business or enterprise add-ons; organizations on the essential plan have only one admin -->
                            <!-- <v-btn icon :to="{ name: 'organization-invite-user', params: { organizationId: this.$route.params.organizationId } }">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                            </v-btn> -->
                        </v-app-bar>
                        <v-list dense nav>
                            <v-list-item v-if="userList.length === 0">No users yet.</v-list-item>
                            <v-list-item v-for="(item, idx) in userList" v-bind:key="idx" class="my-2" @click="selectUser(item)">
                                <v-list-item-content class="text-start">
                                    <span>
                                    {{ item.user.name }}
                                    &lt;{{ item.user.email }}&gt;
                                    <v-chip small v-if="item.is_admin">Admin</v-chip>
                                    </span>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import AccountBar from '@/components/AccountBar.vue';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';

export default {
    components: {
        AccountBar,
        AccessDeniedOverlay,
    },
    data: () => ({
        userList: null,
        error: null,
        forbiddenError: false,
        account: null, // only if query specifies an account; otherwise we search all accounts
    }),
    computed: {
        ...mapState({
            organization: (state) => state.organization,
        }),
    },
    watch: {
        $route(newValue, oldValue) {
            this.loadUserList();
            if (newValue.query.account_id !== oldValue.query.account_id) {
                this.loadAccount();
            }
        },
    },
    methods: {
        async loadUserList() {
            try {
                this.$store.commit('loading', { loadUserList: true });
                const query = {
                    account_id: null,
                };
                if (this.$route.query.account_id) {
                    query.account_id = this.$route.query.account_id;
                }
                const response = await this.$client.organization(this.$route.params.organizationId).linkAccountUser.search(query);
                if (response?.list) {
                    this.userList = response.list;
                } else {
                    this.userList = null;
                }
            } catch (err) {
                console.error('loadUserList failed', err);
            } finally {
                this.$store.commit('loading', { loadUserList: false });
            }
        },
        selectUser(item) {
            this.$router.push({ name: 'organization-view-user', params: { organizationId: this.$route.params.organizationId, userId: item.user_id } });
        },
        async loadAccount() {
            try {
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.organization(this.$route.params.organizationId).account.get({ id: this.$route.query.account_id });
                if (response) {
                    this.account = response;
                } else {
                    this.account = null;
                }
            } catch (err) {
                console.error('loadAccount failed', err);
                if (err.response?.status === 403) {
                    this.forbiddenError = true;
                }
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
    },
    mounted() {
        this.loadUserList();
        if (this.$route.query.account_id) {
            this.loadAccount();
        }
    },
};
</script>
